import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ThemesService } from '../../services/themes.service';

@Component({
  selector: 'app-theme-switcher',
  templateUrl: './theme-switcher.component.html',
  styleUrls: ['./theme-switcher.component.scss'],
})
export class ThemeSwitcherComponent implements OnInit, OnDestroy {
  @Input()
  public sliderText = '';

  constructor(public themesService: ThemesService, public router: Router) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
