import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { sessionExpirationTimeKey } from '../services/auto-logout.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse) {
            const expirationTime = event.headers.get(sessionExpirationTimeKey);
            if (expirationTime) {
              localStorage.setItem(sessionExpirationTimeKey, expirationTime);
            }
          }
        },
        (err) => {
          if (err instanceof HttpErrorResponse) {
            const url = location.toString();
            if (
              err.status !== 401 ||
              url.includes('/login') ||
              url.includes('/signup') ||
              url.includes('/invite-signup') ||
              url.includes('/forgot-password') ||
              url.includes('/reset-password')
            ) {
              return;
            }

            this.router.navigate(['/login'], {
              queryParams: { redirectTo: url },
            });
          }
        },
      ),
    );
  }
}
