import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { InactiveSessionsDialogComponent } from '../modules/inactive-sessions/components/inactive-sessions-dialog.component';
import { AuthService } from './auth.service';
import { ThemesService } from './themes.service';

export const closeModalEvent = 'timeOut';
export const sessionExpirationTimeKey = 'SESSION-EXPIRATION-TIME';

@Injectable({
  providedIn: 'root',
})
export class AutoLogoutService {
  private intervalId: any;
  private showModal = false;
  private ref: MatDialogRef<InactiveSessionsDialogComponent, any>;
  private checkTime = 2000; // 2 second

  constructor(
    private dialog: MatDialog,
    private themesService: ThemesService,
    private authService: AuthService,
  ) {
    this.startSessionTimer();
  }

  private startSessionTimer() {
    this.stopSessionTimer();

    this.intervalId = setInterval(() => this.logSessionTime(), this.checkTime);
  }

  private stopSessionTimer() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  private logSessionTime() {
    const expirationTime = Number(
      localStorage.getItem(sessionExpirationTimeKey),
    );
    if (!expirationTime) {
      return;
    }

    const remainingTime = expirationTime - Math.floor(Date.now() / 1000);
    if (remainingTime <= 0) {
      if (this.ref) {
        this.ref.close(closeModalEvent);
      }
      this.stopSessionTimer();
      return;
    }

    if (remainingTime < 60 && !this.showModal) {
      this.openInactiveModal();
      this.showModal = true;
      return;
    }

    if (remainingTime > 60 && this.ref) {
      this.ref.close();
    }
  }

  private openInactiveModal() {
    this.ref = this.dialog.open(InactiveSessionsDialogComponent, {
      maxWidth: '713px',
      maxHeight: '300px',
      panelClass: [this.themesService.getDarkThemeClass(), 'auto-logout'],
    });

    this.ref.afterClosed().subscribe(async (res) => {
      this.showModal = false;
      if (res === closeModalEvent) {
        this.authService.logout().catch(() => {});
        return;
      }
      await this.authService.loadSession().toPromise();
    });
  }
}
