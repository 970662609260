import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import { routes } from './app-routing.module';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ThemeSwitcherComponent } from './components/theme-switcher/theme-switcher.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ConfirmModalModule } from './modules/confirm-modal/confirm-modal.module';
import { PipesModule } from './pipes/pipes.module';
import { AuthService } from './services/auth.service';
import { LocaliseService } from './services/localise.service';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    ThemeSwitcherComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    RouterModule.forRoot(routes),
    HttpClientModule,
    MatSidenavModule,
    MatProgressBarModule,
    MatBadgeModule,
    MatMenuModule,
    NgxMaskModule.forRoot(maskConfig),
    MatListModule,
    MatCardModule,
    ConfirmModalModule,
    ToastrModule.forRoot(),
    MatExpansionModule,
    ReactiveFormsModule,
    FormsModule,
    PipesModule,
    MatSlideToggleModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (injector: Injector) => async () => {
        await injector
          .get(LocaliseService)
          .getTranslations()
          .catch(() => {});

        const authService = injector.get(AuthService);
        try {
          const session = await authService.loadSession().toPromise();

          if (location.pathname !== '/login') {
            authService.checkSignup(session);
          }
        } catch (e) {}
      },
      deps: [Injector],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
