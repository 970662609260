import { Component, OnDestroy, OnInit } from '@angular/core';

import {
  closeModalEvent,
  sessionExpirationTimeKey,
} from '../../../services/auto-logout.service';

@Component({
  selector: 'app-inactive-sessions-dialog',
  templateUrl: './inactive-sessions-dialog.component.html',
  styleUrls: ['./inactive-sessions-dialog.component.scss'],
})
export class InactiveSessionsDialogComponent implements OnInit, OnDestroy {
  public remainedTime = '00:00';
  private intervalId: any;
  public closeModalEvent = closeModalEvent;

  constructor() {}

  ngOnInit(): void {
    this.updateRemainingTime();

    this.intervalId = setInterval(() => {
      this.updateRemainingTime();
    }, 1000);
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  private updateRemainingTime() {
    const expirationTimestamp = localStorage.getItem(sessionExpirationTimeKey);
    if (!expirationTimestamp) {
      return;
    }

    const expirationTime = parseInt(expirationTimestamp, 10);
    const currentTime = Math.floor(Date.now() / 1000);
    const remainingSeconds = expirationTime - currentTime;

    const minutes = Math.floor(remainingSeconds / 60);
    const seconds = remainingSeconds % 60;
    this.remainedTime = `${this.formatTime(minutes)}:${this.formatTime(
      seconds,
    )}`;
  }

  private formatTime(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }
}
