import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { ThemesService } from '../../services/themes.service';
import {
  ConfirmModalService,
  IConfirmModalSettings,
} from './confirm-modal.service';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent implements OnInit, OnDestroy {
  @ViewChild('confirmTmp')
  confirmTmp: TemplateRef<any>;

  private subscribes: Subscription[] = [];

  constructor(
    private dialog: MatDialog,
    private confirmModalService: ConfirmModalService,
    private themesService: ThemesService,
  ) {}

  ngOnInit(): void {
    this.subscribes.push(
      this.confirmModalService.confirm.subscribe(this.openConfirm),
    );
  }

  ngOnDestroy(): void {
    this.subscribes.forEach((s) => s.unsubscribe());
  }

  openConfirm = (config: IConfirmModalSettings) => {
    this.dialog
      .open(this.confirmTmp, {
        data: config,
        width: config.width,
        panelClass: this.themesService.getDarkThemeClass(),
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          config.confirmAction();
        } else if (config.rejectAction) {
          config.rejectAction();
        }
      });
  };
}
