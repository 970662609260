<ng-template let-data #confirmTmp>
  <h1 mat-dialog-title>{{ data.title || 'Are you sure?' }}</h1>

  <div mat-dialog-content *ngIf="data.body || data.bodyText">
    <ng-container
      *ngIf="data.body"
      [ngTemplateOutlet]="data.body"></ng-container>
    <span *ngIf="data.bodyText">{{ data.bodyText }}</span>

    <p *ngIf="!!data.validator && !!data.validator()" class="form-error-msg">
      {{ data.validator() }}
    </p>
  </div>

  <div mat-dialog-actions [class.actions-right]="data.actionsPositionRight">
    <button mat-button [mat-dialog-close] *ngIf="!data.hideClose">
      {{ data.cancelText || 'No' }}
    </button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="!!data.validator && !!data.validator()"
      [mat-dialog-close]="true"
      cdkFocusInitial>
      {{ data.confirmText || 'Ok' }}
    </button>
  </div>
</ng-template>
