import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../services/auth.service';
import { NavbarService } from '../../services/navbar.service';
import { ThemesService } from '../../services/themes.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  constructor(
    public navbarService: NavbarService,
    public themesService: ThemesService,
    public authService: AuthService,
    public router: Router,
  ) {}
}
