import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class SignFinishedGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  async canActivate(): Promise<boolean> {
    if (!this.authService.authUser?.value) {
      return false;
    }

    return this.authService.checkSignup(this.authService.authUser.value);
  }
}
