import { Routes } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import { DashboardGuard } from './guards/dashboard.guard';
import { SignFinishedGuard } from './guards/sign-finished-guard.service';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard',
  },
  {
    path: '',
    canActivate: [AuthGuard, SignFinishedGuard],
    children: [
      {
        path: 'dashboard',
        canActivate: [DashboardGuard],
        loadChildren: () =>
          import('./pages/dashboard/dashboard.module').then(
            (m) => m.DashboardModule,
          ),
      },
      {
        path: 'widgets',
        loadChildren: () =>
          import('./pages/widgets/widgets.module').then((m) => m.WidgetsModule),
      },
      {
        path: 'widget-rotators',
        loadChildren: () =>
          import('./pages/widget-rotators/widget-rotators.module').then(
            (m) => m.WidgetRotatorsModule,
          ),
      },
      {
        path: 'statements',
        loadChildren: () =>
          import('./pages/statements/statements.module').then(
            (m) => m.StatementsModule,
          ),
      },
      {
        path: 'agents',
        loadChildren: () =>
          import('./pages/agents/agents.module').then((m) => m.AgentsModule),
      },
      {
        path: 'add-agent',
        loadChildren: () =>
          import('./pages/add-agent/add-agent.module').then(
            (m) => m.AddAgentModule,
          ),
      },
      {
        path: 'deals',
        loadChildren: () =>
          import('./pages/deals/deals.module').then((m) => m.DealsModule),
      },
      {
        path: 'bank-account',
        loadChildren: () =>
          import('./pages/banking-info/banking-info.module').then(
            (m) => m.BankingInfoModule,
          ),
      },
      {
        path: 'personal',
        loadChildren: () =>
          import('./pages/personal-info/personal-info.module').then(
            (m) => m.PersonalInfoModule,
          ),
      },
      {
        path: 'administrators',
        loadChildren: () =>
          import('./pages/administrators/administrators.module').then(
            (m) => m.AdministratorsModule,
          ),
      },
      {
        path: 'support',
        loadChildren: () =>
          import('./pages/support/support.module').then((m) => m.SupportModule),
      },
    ],
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./pages/login/components/login-v2/login-v2.component').then(
        (m) => m.LoginV2Component,
      ),
  },
  {
    path: 'forgot-password',
    loadComponent: () =>
      import('./pages/forgot-password/forgot-password.component').then(
        (m) => m.ForgotPasswordComponent,
      ),
  },
  {
    path: 'reset-password/:hash',
    loadComponent: () =>
      import('./pages/reset-password/reset-password.component').then(
        (m) => m.ResetPasswordComponent,
      ),
  },
  {
    path: 'signup',
    loadChildren: () =>
      import('./pages/signup/signup.module').then((m) => m.SignupModule),
  },
  {
    path: 'invite-signup',
    loadChildren: () =>
      import('./pages/invite-signup/invite-signup.module').then(
        (m) => m.InviteSignupModule,
      ),
  },
];
