<h1 mat-dialog-title class="header">
  {{
    'inactive-sessions.component.are_you_still_there'
      | localise : 'Are you still there?'
  }}
</h1>
<div mat-dialog-content>
  <div class="content">
    {{
      'inactive-sessions.component.for_your_security'
        | localise
          : "For your security, we’ll log you out soon due to inactivity. Stay signed in by confirming you're still here."
    }}
  </div>
  <div class="timer-block">
    <div class="timer-content">
      <img src="assets/img/icons/remained-time-icon.svg" />
      {{
        'inactive-sessions.component.your_online_session'
          | localise : 'Your online session will expire in'
      }}
    </div>
    <div class="timer">{{ remainedTime }}</div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="closeModalEvent">
    {{ 'inactive-sessions.component.log_out' | localise : 'Log out' }}
  </button>
  <button mat-button mat-flat-button color="primary" mat-dialog-close>
    {{
      'inactive-sessions.component.resume_session' | localise : 'Resume session'
    }}
  </button>
</div>
