import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';

import { ConfirmModalComponent } from './confirm-modal.component';
import { ConfirmModalService } from './confirm-modal.service';

@NgModule({
  providers: [ConfirmModalService],
  declarations: [ConfirmModalComponent],
  imports: [
    CommonModule,
    MatInputModule,
    MatDatepickerModule,
    FormsModule,
    MatDialogModule,
    MatButtonModule,
  ],
  exports: [ConfirmModalComponent],
})
export class ConfirmModalModule {}
