import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import {
  LayoutClassesEnum,
  LayoutClassesEnumNames,
} from './models/layout-classes.enum';
import { AuthService } from './services/auth.service';
import { NavbarService } from './services/navbar.service';
import { ThemesService } from './services/themes.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    public breakpointObserver: BreakpointObserver,
    public navbarService: NavbarService,
    public themesService: ThemesService,
    public authService: AuthService,
    public router: Router,
  ) {}

  ngOnInit() {
    this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.XSmall])
      .subscribe((res: BreakpointState) => {
        let layoutCls = LayoutClassesEnum.Desktop;
        if (res?.breakpoints[Breakpoints.XSmall]) {
          layoutCls = LayoutClassesEnum.Mobile;
        } else if (res?.breakpoints[Breakpoints.Small]) {
          layoutCls = LayoutClassesEnum.Tablet;
        }

        this.navbarService.setLayout(layoutCls);
        return this.navbarService.layoutClass;
      });
  }
}
