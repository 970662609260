<div
  *ngIf="
    !router.url.startsWith('/forgot-password') &&
    !router.url.startsWith('/signup') &&
    !router.url.startsWith('/login') &&
    !router.url.startsWith('/add-agent') &&
    !!(authService.authUser | async)?.partnerId
  "
  class="sidenav-sidenav {{ themesService.themeClass }}">
  <div
    *ngIf="!!(authService.authUser | async)"
    class="sidenav-content bg-white">
    <div class="menu-logo-container">
      <button
        mat-button
        type="button"
        class="menu-button"
        (click)="navbarService.closeMenu()"
        *ngIf="!navbarService.isDesktop">
        <img src="assets/img/dashboard/cross.svg" />
      </button>
      <img
        src="assets/img/Tongo_logo_new_2.svg"
        class="sidenav-sidenav__logo"
        alt="Tongo Logo" />
    </div>

    <div class="tab-title" *ngIf="navbarService.isDesktop">
      {{ (authService.authUser | async)?.brokerageName || '&nbsp;' }}
    </div>

    <mat-divider></mat-divider>
    <mat-accordion>
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div>
              {{
                'left-menu.component.your_account' | localise : 'Your Account'
              }}
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <a
          *ngIf="(authService.authUser | async)?.dashboardModule"
          class="link"
          routerLink="/dashboard"
          routerLinkActive="router-link-active"
          (click)="navbarService.closeMenuIfMobile()">
          <mat-icon class="material-icons-outlined icon-size-25">home</mat-icon>
          <div class="nav-item-name">
            {{ 'left-menu.component.dashboard' | localise : 'Dashboard' }}
          </div>
        </a>
        <a
          class="link"
          routerLink="/deals"
          routerLinkActive="router-link-active"
          (click)="navbarService.closeMenuIfMobile()">
          <img
            class="icon-svg icon-size-25"
            src="assets/img/icons/property-commissions.svg" />
          <div class="nav-item-name">
            {{ 'left-menu.component.commissions' | localise : 'Commissions' }}
          </div>
        </a>
        <a
          class="link"
          routerLink="/agents"
          routerLinkActive="router-link-active"
          (click)="navbarService.closeMenuIfMobile()">
          <img
            class="icon-svg icon-size-25"
            src="assets/img/icons/property-agents.svg" />
          <div class="nav-item-name">
            {{ 'left-menu.component.agents' | localise : 'Agents' }}
          </div>
        </a>
        <a
          class="link"
          routerLink="/statements"
          routerLinkActive="router-link-active"
          (click)="navbarService.closeMenuIfMobile()">
          <img
            class="icon-svg icon-size-25"
            src="assets/img/icons/property-statements.svg" />
          <div class="nav-item-name">
            {{ 'left-menu.component.statements' | localise : 'Statements' }}
          </div>
        </a>
        <a
          class="link"
          routerLink="/bank-account"
          routerLinkActive="router-link-active"
          (click)="navbarService.closeMenuIfMobile()">
          <img
            class="icon-svg icon-size-25"
            src="assets/img/icons/property-bank-settings.svg " />
          <div class="nav-item-name">
            {{
              'left-menu.component.bank_settings' | localise : 'Bank Settings'
            }}
          </div>
        </a>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-divider></mat-divider>
    <mat-accordion>
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div>
              {{ 'left-menu.component.setting' | localise : 'Setting' }}
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <a
          class="link"
          routerLink="/personal"
          routerLinkActive="router-link-active"
          (click)="navbarService.closeMenuIfMobile()">
          <img
            class="icon-svg icon-size-25"
            src="assets/img/icons/profile-settings.svg" />
          <div class="nav-item-name">
            {{
              'left-menu.component.profile_settings'
                | localise : 'Profile settings'
            }}
          </div>
        </a>
        <a
          class="link"
          routerLink="/administrators"
          routerLinkActive="router-link-active"
          (click)="navbarService.closeMenuIfMobile()">
          <img
            class="icon-svg icon-size-25"
            src="assets/img/icons/administrators.svg" />
          <div class="nav-item-name">
            {{
              'left-menu.component.administrators' | localise : 'Administrators'
            }}
          </div>
        </a>
        <a
          *ngIf="(authService.authUser | async)?.widgetModule"
          class="link c-white"
          routerLink="/widgets"
          routerLinkActive="router-link-active"
          (click)="navbarService.closeMenuIfMobile()">
          <mat-icon class="material-icons-outlined icon-size-25"
            >widgets</mat-icon
          >
          <div class="nav-item-name">
            {{ 'left-menu.component.widgets' | localise : 'Widgets' }}
          </div>
        </a>
        <a
          *ngIf="(authService.authUser | async)?.widgetModule"
          class="link c-white"
          routerLink="/widget-rotators"
          routerLinkActive="router-link-active"
          (click)="navbarService.closeMenuIfMobile()">
          <mat-icon class="material-icons-outlined icon-size-25"
            >rotate_90_degrees_cw</mat-icon
          >
          <div class="nav-item-name">
            {{
              'left-menu.component.widget_rotators'
                | localise : 'Widget Rotators'
            }}
          </div>
        </a>
        <a
          class="link"
          routerLink="/support"
          routerLinkActive="router-link-active"
          (click)="navbarService.closeMenuIfMobile()">
          <mat-icon class="material-icons-outlined icon-size-25"
            >help_center</mat-icon
          >
          <div class="nav-item-name">
            {{
              'left-menu.component.help_and_support'
                | localise : 'Help & Support'
            }}
          </div>
        </a>
        <a class="link" (click)="authService.logout()">
          <mat-icon class="material-icons icon-size-25">logout</mat-icon>
          <div class="nav-item-name">
            {{ 'left-menu.component.logout' | localise : 'Logout' }}
          </div>
        </a>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-divider></mat-divider>
    <div class="sidenav-footer">
      <!--      PAY-3034 Retire Dark Mode -->
      <!--      <span class="sidenav-footer-text">{{ 'left-menu.component.footer-text-theme' | localise : 'Theme' }}</span>-->
      <!--      <div class="sidenav-footer-mode">-->
      <!--        <app-theme-switcher [sliderText]="'left-menu.component.footer-text-mode' | localise : 'Dark Mode'"></app-theme-switcher>-->
      <!--      </div>-->
    </div>
  </div>
</div>
