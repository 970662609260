<app-confirm-modal></app-confirm-modal>
<mat-drawer-container
  class="sidenav-container {{ navbarService.layoutClass }} {{
    themesService.themeClass
  }}">
  <mat-drawer
    [mode]="navbarService.modeMenu"
    [attr.role]="navbarService.isDesktop ? 'dialog' : 'navigation'"
    [opened]="navbarService.isOpenedMenu"
    (closedStart)="navbarService.closeMenu()">
    <app-sidebar></app-sidebar>
  </mat-drawer>
  <mat-drawer-content
    [class.page-body]="
      !router.url.startsWith('/forgot-password') &&
      !router.url.startsWith('/signup') &&
      !router.url.startsWith('/login') &&
      !router.url.startsWith('/invite-signup') &&
      !router.url.startsWith('/forgot-password') &&
      !router.url.startsWith('/add-agent') &&
      !router.url.startsWith('/reset-password')
    "
    class="{{ navbarService.layoutClass }} {{ themesService.themeClass }}">
    <mat-toolbar
      *ngIf="
        !router.url.startsWith('/add-agent') &&
        !router.url.startsWith('/login') &&
        !router.url.startsWith('/signup')
      "
      color="primary"
      class="menu-logo-toolbar {{ navbarService.layoutClass }} {{
        themesService.themeClass
      }}">
      <div class="menu-logo-toolbar-container">
        <button
          mat-button
          type="button"
          class="menu-button"
          (click)="navbarService.openMenu()">
          <img src="assets/img/dashboard/lines.svg" />
        </button>
        <img
          src="assets/img/Tongo_logo_new_2.svg"
          class="sidenav-sidenav__logo"
          alt="Tongo Logo" />
      </div>
    </mat-toolbar>
    <!-- <app-navbar -->
    <!--   *ngIf=" -->
    <!--     (router.url.startsWith('/login') || router.url.startsWith('/signup')) && -->
    <!--     !(authService.authUser | async)?.partnerId -->
    <!--   "></app-navbar> -->
    <div
      class="content-wrapper {{ navbarService.layoutClass }} {{
        themesService.themeClass
      }}">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
